import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { useStore } from "../@core/scenes/mainStore"
import { getImageSrcFromWpData } from "../@utils/wpData.utils"
import { PortraitsSlider } from "../components/sections/portraitsSlider"
import { BtnRetour } from "../components/ui/btnRetour"
import { Helmet } from "react-helmet"
import LANGUAGES from "../Const/languages"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'

export default function Brand({ data }) {
  const LANG = useStore(state => state.language)
  const post = data.single.nodes[0]
  const allPostsVisionnaires = data.allVisionnaires.nodes.filter(
    value => value.language.code === LANG
  )



  // Logo color
  useEffect(() => {
    useStore.setState({ theme: "white" })

    if (useStore.getState().pointsCloud !== null)
      useStore.getState().pointsCloud.morphTo("positionC")
  }, [])

  // Query ALL Visionnaires needed for the bottom list of visionnaires
  if (post === undefined || post === null) return null

  let urlRelatedBrand = ""
  let thumbRelatedBrand = ""

  if (post.visionnaire_post.entrepriseLinkTest !== null) {
    urlRelatedBrand = post.visionnaire_post.entrepriseLinkTest.slug
    thumbRelatedBrand = getImageSrcFromWpData(
      post.visionnaire_post.entrepriseLinkTest.featuredImage.node
    )
  }

  const thumbnail = getImageSrcFromWpData(post.visionnaire_post.portrait)
  const ITWAudio = post.visionnaire_post.interviewAudio
  let audioUrl = ""
  if (ITWAudio !== null) audioUrl = ITWAudio.localFile.url
  let ITW = post.visionnaire_post.interviewDatas



  return (
    <Layout localClass="page-portrait" showPointClouds={true}>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <section className="visionnaire-header full-grid contentMainContainer">
        <BtnRetour link="/visionnaires" />
        <div className="thumbnail">
          <img src={thumbnail} alt={post.title} />
        </div>
        <h1>{post.title}</h1>
        <div className="description">
          <h2>Portrait</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: post.visionnaire_post.description,
            }}
          />
        </div>
      </section>

      {
        ITWAudio &&
          <section className="visionnaire_audio">
            <div>
              <div className="playerAudio">
                <h2>Écouter l’interview</h2>
                <AudioPlayer
                    autoPlay = {false}
                    src={audioUrl}

                  />
              </div>
            </div>
          </section>
      }

      <section className="visionnaire-interview">
        {ITW !== null &&
          ITW.map((value, index) => {
            // images
            let image = null
            if (value.visuelAside)
              image = getImageSrcFromWpData(value.visuelAside)

            return (
              <InterviewQuestion
                key={index}
                index={index + 1}
                question={value.question}
                answer={value.answer}
                journalist={post.visionnaire_post.interviewerName}
                guest={post.visionnaire_post.guestName}
                image={image}
              />
            )
          })}
      </section>
      <section className="discover-brand-related">
        <div
          className="thumbnail-container"
          style={{ background: `url(${thumbRelatedBrand})` }}
        >
          <Link to={`/${urlRelatedBrand}`}>
            {LANGUAGES[LANG].discoverProject_1}
            <br />
            {LANGUAGES[LANG].discoverProject_2}
          </Link>
          <Link to={`/${urlRelatedBrand}`}>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="32" cy="32" r="32" fill="white" />
              <rect
                x="22"
                y="30.7744"
                width="20"
                height="2.47487"
                rx="1.23744"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.2001 24.1257C33.6889 23.6417 34.4813 23.6417 34.9701 24.1257L42.0502 31.136C42.539 31.6199 42.539 32.4046 42.0502 32.8886L34.9701 39.8989C34.4813 40.3828 33.6889 40.3828 33.2001 39.8989C32.7113 39.4149 32.7113 38.6303 33.2001 38.1463L39.3952 32.0123L33.2001 25.8782C32.7113 25.3943 32.7113 24.6096 33.2001 24.1257Z"
                fill="black"
              />
            </svg>
          </Link>
        </div>
      </section>
      <section className="list-of-visionnaires">
        <h2 className="otherTitle">{LANGUAGES[LANG].morePortraits}</h2>
        <PortraitsSlider data={allPostsVisionnaires} />
      </section>
    </Layout>
  )
}

const InterviewQuestion = props => {

  return (
    <div className="interview-question-bloc contentMainContainer">
      <div className="interview-number">{props.index}</div>
      <div className="interview-question">
        <div className="journalist">{props.journalist}</div>
        <h2
          className="content"
          dangerouslySetInnerHTML={{
            __html: props.question,
          }}
        />
      </div>
      <div className="interview-answer">
        <div className="guest">{props.guest}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: props.answer,
          }}
        />
      </div>
      <div className="interview-image">
        <img src={props.image} alt="" />
      </div>
    </div>
  )
}

// MAIN QUERY DATA
export const query = graphql`
  query($slug: String!) {
    single: allWpVisionnaire(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          code
        }
        title
        visionnaire_post {
          description
          interviewerName
          guestName
          portrait {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
          brandIcon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          interviewAudio {
            localFile {
              url
            }
          }
          interviewDatas {
            question
            answer
            visuelAside {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          entrepriseLinkTest {
            ... on WpEntreprise {
              slug
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allVisionnaires: allWpVisionnaire {
      nodes {
        language {
          code
        }
        title
        uri
        slug
        visionnaire_post {
          entrepriseLinkTest {
            ... on WpEntreprise {
              id
              title
            }
          }
          portrait {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          brandIcon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
